<app-layout></app-layout>
<div class="content">
    <div style="margin-bottom: 30px;">
        <mat-form-field>
            <mat-label style="color: black;font-size: 15px;">Filter By:</mat-label>
            <mat-select [(value)]="selectedFilter" (selectionChange)="onChangeCategory($event.value)">
                <mat-option value="all">All</mat-option>
                <mat-option value="daily">Daily</mat-option>
                <mat-option value="weekly">Weekly</mat-option>
                <mat-option value="monthly">Monthly</mat-option>
                <mat-option value="annually">Annually</mat-option>
            </mat-select>
        </mat-form-field>

    </div>


    <div style="display: flex; flex-direction: column; align-items: center; margin-bottom: 15px;">
        <div style="display: flex; justify-content: space-between; width: 100%; margin-bottom: 40px;">
            <div routerLink="/manage-profile/list" class="clickable-div">
                <h3 style="font-weight: bold; margin-bottom: 5px;">Registered Customers</h3>
                <p style="font-weight: bold; font-size: 28px;">{{RegisteredCustomers}}</p>
            </div>
            <div routerLink="/manage-book/list" class="clickable-div">
                <h3 style="font-weight: bold; margin-bottom: 5px;">Bookings Received</h3>
                <p style="font-weight: bold;font-size: 28px;">{{RegisteredBookings}}</p>
            </div>
            <div routerLink="/manage-payments/list" class="clickable-div">
                <h3 style="font-weight: bold; margin-bottom: 5px;">Revenue Generated</h3>
                <p style="font-weight: bold;font-size: 28px;">45</p>
            </div>
        </div>
    </div>
</div>

<ng-template #templateImage1>
    <div class="modal-header">
        <button type="button" class="close pull-right close-button-black-border" aria-label="Close" (click)="modalRef.hide(); reset()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="text-align: center;">
        <img src="../../../assets/new images/quick.png" alt="Quick Image" class="quickbooks-image">
        <button class="quickbooks-button" (click)="quickBooks()">Connect to QuickBooks</button>
    </div>
</ng-template>


  
  
  